import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import Whatsapp from './Whatsapp';
import logo from "../images/p23.png";

const Footer = ({ handleMenuClick }) => {
  return (
    <footer className="bg-teal-100 text-black py-8">
      <Whatsapp />
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-center sm:text-left">
          <div>
            <img
              src={logo}
              alt="Prabhakar Real Tech"
              className="mx-auto sm:mx-0 mb-4"
              height={200}
              width={200}
            />
            <address className="not-italic mb-4">
              PC Chamber Ground Floor, Near SBI Bank,<br />
              Metro Station, Sector - 59, Gali No. - 2,<br />
              Mamura Sector - 66 Noida<br />
              <a href="mailto:info@prabhakarrealtech.com" className="hover:underline">
                info@prabhakarrealtech.com
              </a>
            </address>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Navigations</h2>
            <ul>
              <li onClick={() => handleMenuClick('about')}><a href="#" className="hover:underline">About Us</a></li>
              <li><a href="#" className="hover:underline">FAQs Page</a></li>
              <li onClick={() => handleMenuClick('contact')}><a href="#" className="hover:underline">Contact</a></li>
              <li><a href="#" className="hover:underline">Blog</a></li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">The Highlights</h2>
            <ul>
              <li onClick={() => handleMenuClick('ph1')}><a href="#" className="hover:underline">Phase 1</a></li>
              <li onClick={() => handleMenuClick('ph2')}><a href="#" className="hover:underline">Phase 2</a></li>
              <li onClick={() => handleMenuClick('vri')}><a href="#" className="hover:underline">Phase 3</a></li>
              <li><a href="#" className="hover:underline">Vrindavan Township</a></li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg font-semibold mb-2">Follow Us</h2>
            <div className="flex justify-center sm:justify-start space-x-4">
              <a href="#"><FaFacebook size={24} /></a>
              <a href="#"><FaTwitter size={24} /></a>
              <a href="#"><FaInstagram size={24} /></a>
              <a href="#"><FaYoutube size={24} /></a>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-4 pt-4 flex flex-col sm:flex-row justify-between items-center text-center">
          <p className="text-sm text-gray-500">&copy; 2024 Prabhakar Real Tech Enterprises. All Rights Reserved.</p>
          <div className="flex justify-center space-x-4 mt-4 sm:mt-0">
            <a href="#"><FaFacebook /></a>
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaInstagram /></a>
            <a href="#"><FaYoutube /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
