 // App.js
import React, { useState, Suspense, lazy } from 'react';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import AddProjectForm from './components/AddToForm';
 
// Lazy imports for other components
const AboutUs = lazy(() => import('./components/AboutUs'));
const Projects = lazy(() => import('./components/Card'));
const Carousel = lazy(() => import('./components/Carsouel'));
const ImageSlider = lazy(() => import('./components/ImageSlider'));
const ImageSlider2 = lazy(() => import('./components/SliderBoth'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const InfoSection = lazy(() => import('./components/InfoTech'));
const InfoSection1 = lazy(() => import('./components/Phase2'));
const InfoSection2 = lazy(() => import('./components/Phase3'));
const InfoSection3 = lazy(() => import('./components/Vrindawan'));
const Sec = lazy(() => import('./components/ShowAllProjects'));

function App() {
  const [selectedMenuItem, setSelectedMenuItem] = useState('home');
 
  const handleMenuClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <div>
      <NavBar handleMenuClick={handleMenuClick} />
      <Suspense fallback={<div>Loading...</div>}>
        {selectedMenuItem === 'home' && (
          <>
            <Carousel />
            <Projects handleMenuClick={handleMenuClick} />
            <ImageSlider />
            <ImageSlider2 />
          </>
        )}
        {selectedMenuItem === 'about' && <AboutUs />}
        {selectedMenuItem === 'contact' && <ContactUs />}
        {selectedMenuItem === 'ph1' && <InfoSection />}
        {selectedMenuItem === 'ph2' && <InfoSection1 />}
        {selectedMenuItem === 'ph3' && <InfoSection2 />}
        {selectedMenuItem === 'vri' && <InfoSection3 />}
        {selectedMenuItem==='add' && <AddProjectForm/>}
        {selectedMenuItem==='View' && <Sec/>}
        {selectedMenuItem==='enquire' && <ContactUs/>}
      </Suspense>
      <Footer handleMenuClick={handleMenuClick} />
    </div>
  );
}

export default App;
