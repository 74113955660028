import { useState } from 'react';
import { Mail, Lock, Edit3 } from "lucide-react";
import { ImCross } from 'react-icons/im';

const LoginForm = ({ setShowLogin, onLoginSuccess }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simulate login validation
    if (formData.email === 'prabhakarbsr@gmail.com' && formData.password === 'Prabhakar') {
      setStatus('Logging in...');
      setTimeout(() => {
        setStatus('');
        onLoginSuccess(); // Trigger the parent component's login success handler
      }, 1000);
    } else {
      setStatus('Invalid email or password. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="w-full max-w-md bg-white rounded-lg p-8 shadow-lg relative transform hover:scale-105 transition duration-300">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition duration-300"
          onClick={() => setShowLogin(false)}
        >
          <ImCross size={20} />
        </button>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex items-center border rounded p-2 shadow-md">
            <Mail className="mr-2 text-gray-500" />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full p-2 outline-none bg-transparent"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4 flex items-center border rounded p-2 shadow-md">
            <Lock className="mr-2 text-gray-500" />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="w-full p-2 outline-none bg-transparent"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-1 rounded flex items-center justify-center transform hover:bg-blue-600 transition duration-300 shadow-md"
          >
            {status ? status : 'Login'}
            <Edit3 className="ml-2" />
          </button>
          {status && <p className="mt-4 text-center">{status}</p>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
