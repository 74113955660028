 
import { useState } from 'react';
import { Smile, Mail, Phone, MessageCircle, Edit3 } from "lucide-react";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'phone') {
      if (!/^\d{10}$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: 'Phone number must be exactly 10 digits.',
        }));
      } else {
        setErrors((prevErrors) => {
          const { phone, ...rest } = prevErrors;
          return rest;
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!/^\d{10}$/.test(formData.phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Phone number must be exactly 10 digits.',
      }));
      return;
    }

    setStatus('Sending...');
    try {
      const res = await fetch('https://ssr-jmi9.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: formData.email,
          subject: formData.subject,
          text: `Name: ${formData.name}\nPhone: ${formData.phone}\nSubject: ${formData.subject}\n\nMessage:\n${formData.message}`
        }),
      });

      if (res.ok) {
        setStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
        setErrors({});
      } else {
        setStatus('Failed to send message.');
      }
    } catch (error) {
      setStatus('Failed to send message.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-2 bg-gradient-to-r from-blue-500 to-green-500 text-white">
      <div className="w-full max-w-6xl mx-auto flex flex-col items-center px-4">
        <h1 className="text-4xl font-bold mb-8 text-center">Contact Us</h1>
        <div className="w-full md:w-1/2 bg-white bg-opacity-80 text-black rounded-lg p-8 shadow-lg transform hover:scale-105 transition duration-300 flex flex-col md:flex-row">
          <div className="flex flex-col items-center md:items-start md:mr-8 mb-8 md:mb-0">
            <button
              type="button"
              className="w-full bg-green-500 text-white p-4 mb-4 rounded flex items-center justify-center transform hover:bg-green-600 transition duration-300 shadow-md"
              onClick={() => window.location.href = "mailto:prabhakarbsr@gmail.com"}
            >
              <Mail className="mr-2" /> Mail
            </button>
            <button
              type="button"
              className="w-full bg-red-500 text-white py-2 rounded flex items-center justify-center transform hover:bg-red-600 transition duration-300 shadow-md"
              onClick={() => window.location.href = "tel:9258101056"}
            >
              <Phone className="mr-2" /> Call
            </button>
          </div>
          <div className="w-full">
            <form onSubmit={handleSubmit}>
              <div className="mb-4 flex items-center border rounded p-2 shadow-md">
                <Smile className="mr-2 text-gray-500" />
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="w-full p-2 outline-none bg-transparent"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4 flex items-center border rounded p-2 shadow-md">
                <Mail className="mr-2 text-gray-500" />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="w-full p-2 outline-none bg-transparent"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4 flex items-center border rounded p-2 shadow-md">
                <Phone className="mr-2 text-gray-500" />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  className="w-full p-2 outline-none bg-transparent"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              {errors.phone && (
                <p className="text-red-500 text-sm mb-4">{errors.phone}</p>
              )}
              <div className="mb-4 flex items-center border rounded p-2 shadow-md">
                <MessageCircle className="mr-2 text-gray-500" />
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  className="w-full p-2 outline-none bg-transparent"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4 flex items-center border rounded p-2 shadow-md">
                <textarea
                  name="message"
                  placeholder="Message"
                  className="w-full p-2 outline-none bg-transparent"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded flex items-center justify-center transform hover:bg-blue-600 transition duration-300 shadow-md"
              >
                <Edit3 className="mr-2" /> Send
              </button>
              {status && <p className="mt-4 text-center">{status}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
