import { useState, useEffect } from "react";
import {
  FaBars,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaArrowRight,
  FaYoutube,
  FaSignInAlt,
  FaHome,
  FaInfoCircle,
  FaEnvelope,
  FaEye,
  FaBuilding,
  FaPlusCircle
} from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { IoIosArrowDropdown } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import logo from "../images/p23.png";
import LoginForm from "./LoginForm.js"; // Import the login form component
import ContactUs from "./ContactUs.js";

const NavBar = ({ handleMenuClick }) => {
  const [navbar, setNavbar] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login status

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = (menu) => {
    handleMenuClick(menu);
    setNavbar(false);
    setOpenDropdown(null); // Close dropdown on link click
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLogin(false);
  };

  return (
    <>
      <div
        className={`bg-gray-800 text-white py-2 z-10000 relative ${
          scrollY > 100 ? "hidden" : ""
        }`}
      >
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
          <div
            className="flex items-center mb-4 md:mb-0 cursor-pointer"
            onClick={() =>
              (window.location.href = "mailto:info@prabhakarrealtech.com")
            }
          >
            <MdEmail size={24} className="mr-2" />
            <span>info@prabhakarrealtech.com</span>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube size={24} />
            </a>
          </div>
        </div>
      </div>
      <nav
        className={`w-full bg-white ${
          scrollY > 100 ? "fixed top-0 z-50" : "relative"
        } font-sans transition-all duration-300`}
      >
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div className="flex items-center justify-between py-3 md:py-5 md:w-auto w-full">
            <div className="flex items-center">
              <span className="flex items-center cursor-pointer left-10">
                <img
                  src={logo}
                  className="w-28 h-28 md:w-80 md:h-50"
                  alt="logo"
                />
              </span>
              <div className="flex items-center ml-0">
                {isLoggedIn ? (
                  <button
                    onClick={() => handleMenuClick("add")}
                    className="mr-4 flex items-center bg-transparent border-2 border-purple-600 text-purple-600 px-2 md:px-6 py-1 rounded-full shadow-lg hover:bg-purple-600 hover:text-white hover:shadow-xl transition duration-300"
                  >
                    Project <FaPlusCircle className="ml-2" />
                  </button>
                ) : (
                  <button
                    onClick={() => setShowLogin(true)}
                     className="mr-4 flex items-center bg-transparent border-2 border-purple-600 text-purple-600 px-2 md:px-6 py-1 rounded-full shadow-lg hover:bg-purple-600 hover:text-white hover:shadow-xl transition duration-300"
                  >
                    Login <FaSignInAlt className="ml-2" />
                  </button>
                )}
                <button
                  onClick={() => handleLinkClick("enquire")}
                  className="flex items-center bg-transparent border-2 border-purple-600 text-purple-600 px-4 md:px-6 py-1 rounded-full shadow-lg hover:bg-purple-600 hover:text-white hover:shadow-xl transition duration-300"
                >
                  Enquire <FaEnvelope className="ml-2" />
                </button>
              </div>
            </div>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? <ImCross size={30} /> : <FaBars size={30} />}
              </button>
            </div>
          </div>
          
          <div
            className={`fixed top-0 left-0 w-full h-full bg-white z-40 transition-transform transform ${
              navbar ? "translate-x-0" : "-translate-x-full"
            } md:relative md:translate-x-0 md:flex md:items-center md:justify-between`}
          >
            <div className="flex-1 justify-self-center mt-8 md:mt-0">
              <ul className="flex flex-col h-screen md:h-auto items-center justify-center md:flex-row">
                <li className="pb-6 text-xl text-black py-2 md:px-6 text-center border-b-2 md:border-b-0 border-purple-900 md:hover:text-purple-600 md:hover:bg-transparent">
                  <span
                    onClick={() => handleLinkClick("home")}
                    className="cursor-pointer flex items-center"
                  >
                    <FaHome className="mr-2" /> Home
                  </span>
                </li>
                <li className="pb-6 text-xl text-black py-2 md:px-6 text-center border-b-2 md:border-b-0 border-purple-900 md:hover:text-purple-600 md:hover:bg-transparent">
                  <span
                    onClick={() => handleLinkClick("View")}
                    className="cursor-pointer flex items-center"
                  >
                    <FaEye className="mr-2" /> View Project
                  </span>
                </li>
                <li className="pb-6 text-xl text-black py-2 px-6 text-center border-b-2 md:border-b-0 border-purple-900 md:hover:text-purple-600 md:hover:bg-transparent">
                  <span
                    onClick={() => handleLinkClick("about")}
                    className="cursor-pointer flex items-center"
                  >
                    <FaInfoCircle className="mr-2" /> About Us
                  </span>
                </li>
                <li className="pb-6 text-xl text-black py-2 px-6 text-center border-b-2 md:border-b-0 border-purple-900 md:hover:text-purple-600 md:hover:bg-transparent">
                  <span
                    onClick={() => handleLinkClick("contact")}
                    className="cursor-pointer flex items-center"
                  >
                    <FaEnvelope className="mr-2" /> Contact Us
                  </span>
                </li>
              </ul>
            </div>
            <button
              className="absolute top-4 right-4 md:hidden"
              onClick={() => setNavbar(false)}
            >
              <ImCross size={30} className="text-gray-700" />
            </button>
          </div>
        </div>
      </nav>
      
      {showLogin && <LoginForm onLoginSuccess={handleLoginSuccess} setShowLogin={setShowLogin} />}{" "}
      {/* Render the login form */}
    </>
  );
};

export default NavBar;
